export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('page:finish', () => {
    const { isHydrating } = useNuxtApp();
    if (history.state.scroll) {
      setTimeout(() => window.scrollTo(history.state.scroll), 100);
    } else if (isHydrating === false) {
      setTimeout(() => window.scrollTo(0, 0), 100);
    }
  });
});
