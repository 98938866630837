import revive_payload_client_kBgDf3KlxZ from "/temp/build/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_cpwe3dng2gdsllx427bg35vsee/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ywbEc9ZiZG from "/temp/build/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_cpwe3dng2gdsllx427bg35vsee/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_U4j6zG56zT from "/temp/build/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_cpwe3dng2gdsllx427bg35vsee/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_4nb58WfQ7Z from "/temp/build/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.22.4_vite@5.4.8_@types+node@22.7.0_sass@1.79._24rnj7zqdn4nw3odsc7cqrv6gy/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_xcCRLRDVDe from "/temp/build/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_cpwe3dng2gdsllx427bg35vsee/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_crW2zrH8QC from "/temp/build/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_cpwe3dng2gdsllx427bg35vsee/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_SzSB0hU5M6 from "/temp/build/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_cpwe3dng2gdsllx427bg35vsee/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_FPRSamZ4N6 from "/temp/build/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_cpwe3dng2gdsllx427bg35vsee/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_VQIxS4wqOh from "/temp/build/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.22.4_typescript@5.6.2_vue@3.5.8_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/temp/build/.nuxt/components.plugin.mjs";
import prefetch_client_tY6IKDyhps from "/temp/build/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_cpwe3dng2gdsllx427bg35vsee/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import motion_zSVhEj7Xaj from "/temp/build/node_modules/.pnpm/@vueuse+motion@2.2.6_magicast@0.3.5_rollup@4.22.4_vue@3.5.8_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@vueuse/motion/dist/nuxt/runtime/templates/motion.js";
import nuxt_plugin_GXURUeAFZs from "/temp/build/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_magicast@0.3.5_rollup@4.22.4_webpack-sources@3.2.3/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import nuxt3_plugin_ccr9E8jSRm from "/temp/build/node_modules/.pnpm/vue-yandex-maps@2.1.4_@vue+runtime-core@3.5.8_nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@2_mgjgmhigvprif2dq6mq635g5ke/node_modules/vue-yandex-maps/dist/plugins/nuxt3-plugin.js";
import vmask_MNloZULcpN from "/temp/build/src/plugins/vmask.ts";
import nuxtServerInit_LTWY2PUaTk from "/temp/build/src/plugins/nuxtServerInit.ts";
import scrollTop_e7v3LLiez9 from "/temp/build/src/plugins/scrollTop.ts";
import sentryInit_client_D9KT86gqsm from "/temp/build/src/plugins/sentryInit.client.ts";
export default [
  revive_payload_client_kBgDf3KlxZ,
  unhead_ywbEc9ZiZG,
  router_U4j6zG56zT,
  _0_siteConfig_4nb58WfQ7Z,
  payload_client_xcCRLRDVDe,
  navigation_repaint_client_crW2zrH8QC,
  check_outdated_build_client_SzSB0hU5M6,
  chunk_reload_client_FPRSamZ4N6,
  plugin_vue3_VQIxS4wqOh,
  components_plugin_KR1HBZs4kY,
  prefetch_client_tY6IKDyhps,
  motion_zSVhEj7Xaj,
  nuxt_plugin_GXURUeAFZs,
  nuxt3_plugin_ccr9E8jSRm,
  vmask_MNloZULcpN,
  nuxtServerInit_LTWY2PUaTk,
  scrollTop_e7v3LLiez9,
  sentryInit_client_D9KT86gqsm
]